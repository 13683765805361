import { Routes } from '@angular/router';
import { authGuard } from './auth.guard';

const authRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./auth/login/login.component').then((c) => c.LoginComponent),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./auth/forgot-password/forgot-password.component').then(
        (c) => c.ForgotPasswordComponent
      ),
  },
  { path: 'auth', redirectTo: '/login', pathMatch: 'full' },
];

const defaultRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: '**',
    loadComponent: () =>
      import('./error/path-not-found/path-not-found.component').then(
        (c) => c.PathNotFoundComponent
      ),
  },
];

const appRoutes: Routes = [
  {
    path: 'main-app',
    loadComponent: () =>
      import('./pages/main-app/main-app.component').then(
        (c) => c.MainAppComponent
      ),
      canActivate: [authGuard],

    children: [
      {
        path: 'search-patient',
        loadComponent: () =>
          import(
            '../app/components/search-patient/search-patient.component'
          ).then((c) => c.SearchPatientComponent),
          canActivate: [authGuard],
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
    ],
  },
];
 const caseListRoutes: Routes = [
  {
    path: 'case-list/:accountNumber',
    loadComponent: () =>
      import('./pages/case-details/case-details.component').then(
        (c) => c.CaseDetailsComponent
      ),  canActivate: [authGuard],
    children: [
      {
        path: 'demographics',
        loadComponent: () =>
          import('../app/components/demograpics/demograpics.component').then(
            (c) => c.DemograpicsComponent
          ),
          canActivate: [authGuard],
      },
      {
        path: 'documents',
        loadComponent: () =>
          import('../app/components/documents/documents.component').then(
            (c) => c.DocumentsComponent
          ),
          canActivate: [authGuard],
      },
    ], 
  },
]; 

export const routes: Routes = [
  ...authRoutes,
  ...appRoutes,
  ...caseListRoutes,
  ...defaultRoutes,
];
